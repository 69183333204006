// Bootstrap overrides
$font-family-sans-serif: "IBM Plex Sans", -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$body-bg: #f1eee7;
$primary: #605c59;
$headings-font-weight: 400;
$thumbnail-bg: white;
$border-color: darken($body-bg, 15%);

$pswp__assets-path: "../node_modules/photoswipe/src/css/default-skin/";
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/photoswipe/src/css/main.scss';
@import '../node_modules/photoswipe/src/css/default-skin/default-skin.scss';

img {
  max-width: 100%;
}

figcaption {
  @extend .figure-caption;
}

.card {
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transform: scale(1.02);
}

#language-switch {
  opacity: 0.3;
}

#language-switch:hover {
  opacity: 1;
  transition: opacity ease-in-out 0.3s;
}

#horses {
  .horse--has-left {
    display: none;
  }

  &.show-all {
    .horse--has-left {
      display: block;
    }
  }

  &.show-for-sale {
    .horse {
      display: none;
    }
    .horse--for-sale {
      display: block;
    }
  }
}
.horse--has-left {
  filter: grayscale(100%);
  opacity: .8;
  transition: all 0.3s ease-in-out;
}
.horse--for-sale .card{
  position: relative;
  overflow: hidden;
}
.horse--for-sale .card::after {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  background: #cc0000;
  color: white;
  width: 40%;
  font-size: 12px;
  letter-spacing: 2px;
  content: "À VENDRE";
  text-align: center;
}

.horse--has-left:hover {
  opacity: 1;
  filter: none;
}

section#contact {
  overflow:hidden;
}

section#horses {
  background: rgba(0, 0, 0, .05);
  box-shadow: inset 0 5px 20px rgba(0, 0, 0, .05);
}

ul {
  padding-left: 0;
}

.table--pedigree {
  td {
    @extend .p-1;
    vertical-align: middle;
    letter-spacing: 1px;
    font-size: .75em;
  }
  td[rowspan="4"],
  td[rowspan="2"] {
    font-size: 1em;
    letter-spacing: normal;
  }
}

@include media-breakpoint-down(sm) {
  #main-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 10px 20px 10px;
    background: transparentize(darken($body-bg, 10), 0.2);
    -webkit-backdrop-filter: blur(5px);
    z-index: 10;
  }
}
